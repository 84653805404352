/**
 * Determine the mobile operating system.
 * This function returns one of 'facebook ios', 'facebook android', or 'facebook unknown'.
 *
 * @returns {String}
 */
export default function getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/FBAN|FBAV/.test(userAgent)) {

        if (/android/i.test(userAgent)) {
            return "facebook android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return "facebook ios";
        }

        return "facebook unknown";
    }

    if (/windows phone/i.test(userAgent)) {
        // return "Windows Phone";
        return "unknown";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return "iOS";
    }

    return "unknown";
}