import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from "@mui/material";
import React, {forwardRef, ReactElement, useEffect, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import Chrome from "./help-pages/chrome";
import Samsung from "./help-pages/samsung";
import Opera from "./help-pages/opera";
import Safari from "./help-pages/safari";
import Other from "./help-pages/other";
import BrowserSelection from "./help-pages/browser-selection";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import style from './Help.module.css'
import useLogging from "../../service/UseLogging";
import {constants} from "../../xhr/constants";
import FacebookIos from "./help-pages/facebook-ios";
import FacebookAndroid from "./help-pages/facebook-android";
import FacebookUnknown from "./help-pages/facebook-unknown";
import getMobileOperatingSystem from "../../service/OS";

interface Props {
    open: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    props: { children: ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props}/>;
});

export default function InstallDialog({open, onClose}: Props) {

    const {LOG_CLICK} = useLogging();

    const [selected, setSelected] = useState<string>('initial')
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [facebookBrowser, setFacebookBrowser] = useState<string>('');

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: any) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    useEffect(() => {
        if (!open) {
            setSelected('')
            setDetailsActive(false)
        } else {
            const operatingSystem = getMobileOperatingSystem()
            // Automatically show the Safari browser selection if the OS is iOS
            if (operatingSystem === 'iOS') {
                handleAppleInstallClick()
                setDetailsActive(true)
            } else if (operatingSystem === 'Android') {
                LOG_CLICK("install button confirm (android)")
            } else if (operatingSystem === 'facebook android') {
                setFacebookBrowser('facebook android')
            } else if (operatingSystem === 'facebook ios') {
                setFacebookBrowser('facebook ios')
            } else if (operatingSystem === 'facebook unknown') {
                setFacebookBrowser('facebook unknown')
            }
        }
    }, [open])

    const handleInstallClick = () => {
        // @ts-ignore Track the install click event with Facebook Pixel
        if (!constants.DEBUG) fbq('track', 'SubmitApplication')
        if (deferredPrompt) {
            // @ts-ignore
            deferredPrompt.prompt();
            // @ts-ignore
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    onClose()
                }
                setDeferredPrompt(null);
            });
        } else { // when prompt is not supported by the browser redirect to browser selection page.
            setSelected('browser-selection')
        }
    };

    const handleAppleInstallClick = () => {
        LOG_CLICK("install button confirm (apple)")
        // @ts-ignore Track the install click event with Facebook Pixel
        if (!constants.DEBUG) fbq('track', 'SubmitApplication')
        setSelected('safari')
    }

    const initialContent = <div className={style.installPage}>
        <img src={'/logo_512.png'} alt={''}/>
        <p style={{textAlign: 'center'}}>
            Download nu de app van Goedkoopprinten. <br/>
            Maak hieronder een keuze om de installatie te starten.
        </p>
        {getMobileOperatingSystem() === 'unknown' ? (
            <>
                <Button className={style.installButton} onClick={handleInstallClick} variant={'contained'}>
                    Installeren voor Android gebruikers
                </Button>
                <Button className={style.installButton} onClick={handleAppleInstallClick} variant={'contained'}>
                    Installeren voor Apple gebruikers
                </Button>
            </>
        ) : (
            <>
                <Button className={style.installButton} onClick={handleInstallClick} variant={'contained'}>
                    Installeer als app
                </Button>
            </>
        )}

    </div>

    const [detailsActive, setDetailsActive] = useState<boolean>(false);

    const handleContentSwitch = (selected?: string | null) => {
        if (selected === 'browser-selection' || selected === '') {
            setSelected(selected);
            setDetailsActive(false);
        } else {
            // @ts-ignore
            setSelected(selected)
            setDetailsActive(true);
        }
    }

    const content =
        selected === 'chrome' ? <Chrome/> :
            selected === 'samsung' ? <Samsung/> :
                selected === 'opera' ? <Opera/> :
                    selected === 'safari' ? <Safari/> :
                        selected === 'other' ? <Other/> :
                            selected === 'browser-selection' ? <BrowserSelection onSelection={handleContentSwitch}/> :
                                initialContent;


    const handleFacebookToScannerClick = () => {
        LOG_CLICK("Try out scanner - Facebook click")
        onClose()
    }

    const facebook =
        facebookBrowser === 'facebook android' ? <FacebookAndroid onClose={handleFacebookToScannerClick}/> :
            facebookBrowser === 'facebook ios' ? <FacebookIos onClose={handleFacebookToScannerClick}/> :
                facebookBrowser === 'facebook unknown' ? <FacebookUnknown onClose={handleFacebookToScannerClick}/> : null;

    return <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
        <DialogTitle>
            {detailsActive ?
                <Button sx={{position: 'absolute', left: '8px', top: '8px'}}
                        color={'inherit'}
                        onClick={() => handleContentSwitch('browser-selection')}><ArrowBackIcon/></Button>
                : null}
            {facebookBrowser === '' &&
                <Button sx={{position: 'absolute', right: '8px', top: '8px'}} color={'inherit'}
                        onClick={onClose}><CloseIcon/></Button>
            }
        </DialogTitle>
        <DialogContent sx={{padding: 0}}>
            {facebookBrowser !== '' ? facebook : content}
        </DialogContent>
    </Dialog>
}
