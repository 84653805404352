import React, {CSSProperties} from 'react';
import {Button} from "@mui/material";

interface Props {
    onClose: () => void;
}

export default function FacebookIos({onClose}: Props) {
    return (
        <>
            <style>
                {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
            40% {transform: translateY(-10px);}
            60% {transform: translateY(-5px);}
          }
          .arrow {
            position: absolute;
            top: 20px;
            right: 8px;
            font-size: 1.5em;
            animation: bounce 2s infinite;
            z-index: 9999;
          }
        `}
            </style>
            <div style={container}>
                <img src={'/logo_512.png'} style={{width: '150px'}} alt={''}/>
                <div className="arrow">
                    <img style={{width: '40px', transform: 'rotate(180deg)'}} src={'assets/images/green_arrow_down.png'}
                         alt={'Pijl naar beneden'}/>
                </div>

                <h1>Goedkoopprinten</h1>

                Bedankt voor uw interesse in onze app.
                <br/>
                <br/>

                <h2 style={{marginBottom: 0}}>Direct installeren</h2>
                <div style={step}>
                Om de app te installeren mag u de instructies hieronder volgen. Daarna volgt de installatie.
                </div>
                <br/>
                <div style={step}>
                    <div style={stepIcon}><img src={'assets/images/menu-ios.png'} style={{width: '24px'}}/></div>
                    <div style={stepText}>Tik op de drie puntjes rechtsboven.</div>
                </div>

                <div style={step}>
                    <div style={stepIcon}><img src={'assets/images/external-browser-ios.png'} style={{width: '26px'}}/>
                    </div>
                    <div style={stepText}>Kies "Openen in".</div>
                </div>
                <br/>
                <h2 style={{marginBottom: 8}}>Of probeer de scanner</h2>
                <Button style={{background: '#83ba3b', color: 'white', width: '80%'}} onClick={onClose}>Open
                    scanner</Button>
            </div>
        </>
    );
}

const container: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    overflowX: 'hidden'
};

const title: CSSProperties = {
    fontSize: '1.8em',
    marginBottom: '20px',
    wordWrap: 'break-word',
};

const step: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '400px',
    width: '100%',
    margin: '4px auto',
};

const stepIcon: CSSProperties = {
    fontSize: '1.5em',
    marginRight: '15px',
};

const stepText: CSSProperties = {
    textAlign: 'left',
    lineHeight: '1.4em',
    fontSize: '1em',
};